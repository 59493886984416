<template>
    <button v-if="isPreviewModeEnabled"
        type="button"
        class="fixed bottom-0 right-0 z-40 rounded-tl bg-black/70 px-4 py-2 text-xl text-white"
        @click="exitPreview">
        Exit preview mode
    </button>
</template>

<script setup lang="ts">
const { refresh, session, update } = useSession();

const isPreviewModeEnabled = computed(() => (session.value?.elevatePreview === true));

await refresh();

async function exitPreview() {
    await update({ elevatePreview: false });
}
</script>
